export const monthDate = '5月9日';
// 当前数据如果要强刷缓存则变更以下字段
const curDataVersion = 1;

const hasNumEmoji = '💪';
const matchTargetEmoji = '[红包]';

// export const apiUrl = "http://localhost:4000/api";

export const apiUrl = "https://summer-rush.api.9daye.cn/api";

export function getDataVersion() {
    return monthDate.replaceAll(/[^\d+]/g, '_') + `-${curDataVersion}`
}

const teams = [
    // { group: '所有网格员到店辅导至少1家', type: 'head' },
    // { group: '--------------------------------', type: 'head' },
    // { group: '        [庆祝]【活动日直播榜】[庆祝]', type: 'head' },

    {name: '安徽李明雷', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '福建林颖', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '福建左丽娟', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '广海曾培专', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '广海吴宙鹏', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '广海沈华巧', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '广海戴水平', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '贵州付臣超', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '河南薛少智', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '河南郑长江', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '湖北李鹏', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '湖南赵宝华', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '湖南彭堂武', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '湖南张天鹏', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '江苏王金星', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '江苏陈鋆', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '江苏孙侠', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '江苏陈军', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '江西徐文刚', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '江西艾文高', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '江西艾旺高', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '京津冀张鑫哲', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '京津冀李栋栋', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '京津冀姚建旺', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '山东卢彦萍', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '山东王金梦', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '山东李晨', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '山东霍兵占', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '山西秦伟', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '山西韩照立', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '陕西赵刚', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '上海李帅', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '上海黄煌', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '四川弋淋', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '浙江冯丽丽', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '浙江李战明', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '浙江武玉亮', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '重庆何帮权', filmTarget: 5, bomTarget: 5, type: 'grid'},
    {name: '重庆周仁牧', filmTarget: 5, bomTarget: 5, type: 'grid'},

];

export function buildInitData(finishData) {
    return [...teams].map((item) => {
        if (item.type === 'head') {
            return {...item, id: item.group};
        }

        const id = encodeURI(`${item.name}`);

        const key3 = encodeURI(`${item.name}-film`);
        const key4 = encodeURI(`${item.name}-bom`);

        const curFinish3 = finishData ? (finishData[key3] || 0) : 0;
        const curFinish4 = finishData ? (finishData[key4] || 0) : 0;

        return {...item, filmFinish: curFinish3, bomFinish: curFinish4, id: id};
    });
}

const numEmojiMap = [
    '0️⃣',
    '1️⃣',
    '2️⃣',
    '3️⃣',
    '4️⃣',
    '5️⃣',
    '6️⃣',
    '7️⃣',
    '8️⃣',
    '9️⃣',
];

export function convertNum2Emoji(num, minLen) {
    const str = num + '';
    let arr = str.split('');
    // if (minLen && arr.length < minLen) {
    //     let fillArr = new Array(minLen - arr.length).fill('0');
    //     arr = [...fillArr, ...arr];
    // }

    return arr
        .map(n => numEmojiMap[parseInt(n)])
        .join('');
}

const calcTotal = fTeams => {
    let filmTarget = 0;
    let filmFinish = 0;

    let bomTarget = 0;
    let bomFinish = 0;

    for (const item of fTeams) {
        if (item.type === 'head') {
            continue;
        }

        filmTarget += item.filmTarget || 0;
        filmFinish += item.filmFinish || 0;

        bomTarget += item.bomTarget || 0;
        bomFinish += item.bomFinish || 0;
    }

    return [filmTarget, filmFinish,bomTarget, bomFinish];
};

function teamAchievement(team) {
    // if (team.storeFinish > 0 || team.followFinish > 0 || team.devFinish > 0) {
    //     return hasNumEmoji;
    // }

    // return "";

    if (team.filmFinish >= team.filmTarget && team.bomFinish >= team.bomTarget ) {
        return matchTargetEmoji;
    } else if (team.filmFinish > 0 || team.bomFinish > 0) {
        return hasNumEmoji;
    } else {
        return '';
    }
}

function getTopTxt(fTeams) {
    let arr = fTeams.filter(item => (item.filmFinish && item.filmFinish >= 5));
    if (!arr.length) return null;

    arr.sort((a, b) => -(a.filmFinish - b.filmFinish));
    return arr.map(item => {
        return `${item.name}${convertNum2Emoji(item.filmFinish, 1)}台`;
    }).join('\n');
}

export function formatTxt(fTeams) {
    let [filmTarget, filmFinish,bomTarget, bomFinish] = calcTotal(fTeams);

    const filmTargetLen = (filmTarget + '').length;
    const bomTargetLen = (bomTarget + '').length;

    //     let str = `🏆 优玖铁军，目标必达成 🏆
    // 🌟夏日贴膜节，${monthDate[0]}月活动日🌟
    // ${convertNum2Emoji(monthDate[0], 2)}月${convertNum2Emoji(monthDate[1], 2)}优玖铁军目标${convertNum2Emoji(totalTarget)}台
    // 截止目前已完成${convertNum2Emoji(totalFinish, totalTargetLen)}台
    // --------------------------------
    //       [庆祝]【今日英雄榜】[庆祝]`;

    let str = `🏆优玖铁军，目标必达成🏆
🌟${monthDate}品牌活动日🌟
施工目标${convertNum2Emoji(filmTarget, filmTargetLen)}台，目前已完成${convertNum2Emoji(filmFinish, filmTargetLen)}台
翻拍健康视频${convertNum2Emoji(bomTarget, bomTargetLen)}条，目前已完成${convertNum2Emoji(bomFinish, bomTargetLen)}条
--------------------------------`;

    // const topTxt = getTopTxt(fTeams);
    // if (topTxt) {
    //     str += `\n[庆祝]恭喜以下老师施工达到5台[庆祝]\n${topTxt}\n--------------------------------`;
    // }

    const next = fTeams.map(item => {
        const emojiLen1 = (item.filmTarget + '').length;
        const emojiLen2 = (item.bomTarget + '').length;

        if (item.type === 'head') {
            return item.group;
        } else {
            return `${item.name}目标${item.filmTarget}台+${item.bomTarget}条，已完成${convertNum2Emoji(item.filmFinish, emojiLen1)}台+${convertNum2Emoji(item.bomFinish, emojiLen2)}条${teamAchievement(item)}`;
        }


    }).join('\n');

    return str + '\n       [庆祝]【今日英雄榜】[庆祝]\n' + next;
}
