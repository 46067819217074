import React, { useState, useEffect } from "react";
import { Button, Col, Descriptions, Input, InputNumber, notification, PageHeader, Row, Typography } from "antd";

import './index.less'
import { apiUrl, buildInitData, formatTxt, getDataVersion, monthDate } from "./source";

const { Title, Text } = Typography;
const { Search } = Input;


function filterTeams(teams, q) {
    if (!q || !q.trim().length) {
        return teams;
    }

    const str = q.trim();
    return teams.filter(item => {
        return item.name.includes(str);
    });
}

const initData = buildInitData();

async function getCacheFinishData() {
    const dataVersion = getDataVersion();
    const resp = await fetch(`${apiUrl}/get?version=${dataVersion}`, {});
    const body = await resp.json();
    const rawData = body.success ? body.data : null;
    if (rawData && rawData.length && rawData[0] === "{") {
        return JSON.parse(rawData);
    }

    return null;
}

async function cacheTeams(data) {
    const formData = new FormData();

    const dataVersion = getDataVersion();
    formData.append('version', dataVersion);
    formData.append('data', JSON.stringify(data));

    await fetch(`${apiUrl}/save`,
        {
            body: formData,
            method: "post"
        });
}


function Home() {
    const [teams, setTeams] = useState([]);
    const [allFinish, setAllFinish] = useState({});


    useEffect(() => {
        // run once
        (async function init() {
            const cached = await getCacheFinishData();
            if (cached) {
                setAllFinish(cached);
                setTeams(buildInitData(cached));
            } else {
                setTeams(initData)
            }
        })()
    }, [])


    const [query, setQuery] = useState("");
    const fTeams = filterTeams(teams.filter(item => item.type !== 'head'), query);

    const updateFinishNum = (team, num, type) => {
        const newTeams = teams.map(item => {
            if (team.id === item.id) {
                item[type + 'Finish'] = num;
            }
            return item;
        })
        setTeams(newTeams);

        const newAllFinish = { ...allFinish, [team.id + '-' + type]: num };
        setAllFinish(newAllFinish);
        cacheTeams(newAllFinish);
    };

    const txt = formatTxt(teams);

    const copy = () => {
        navigator.clipboard.writeText(txt);

        notification.open({
            duration: 1.5,
            message: '消息',
            description:
                '已复制到剪贴板',
            onClick: () => {
            },
        });
    };

    const onSearch = (v) => {
        setQuery(v)
    };


    return <div className='home'>
        <Title level={2}>品牌活动日接龙工具</Title>
        <Title level={4}><Text type="secondary">{monthDate} (请交接后的人刷新页面)</Text></Title>

        <Row gutter={{ xs: 0, sm: 0, md: 24 }}>
            <Col xs={24} sm={24} md={12} className="leftSide">
                <PageHeader
                    ghost={false}
                    // onBack={() => window.history.back()}
                    title='达成设置'
                    // subTitle="This is a subtitle"
                    extra={[
                        <Search placeholder="搜索" allowClear onSearch={onSearch} style={{ width: 200 }} />
                    ]}
                    style={{ marginBottom: '10px' }}
                >
                    <Descriptions size="small" column={5} key='header'>
                        <Descriptions.Item className='strong'>人员</Descriptions.Item>
                        <Descriptions.Item className='strong'>目标1</Descriptions.Item>
                        <Descriptions.Item className='strong'>达成1</Descriptions.Item>
                        <Descriptions.Item className='strong'>目标2</Descriptions.Item>
                        <Descriptions.Item className='strong'>达成2</Descriptions.Item>
                    </Descriptions>
                    {
                        fTeams.length > 0 && (
                            fTeams.map(tm => (
                                <Descriptions size="small" column={5} key={tm.id}>
                                    <Descriptions.Item className='strong'>{tm.name}</Descriptions.Item>

                                    <Descriptions.Item>
                                        施工: {tm.filmTarget}台
                                    </Descriptions.Item>
                                    <Descriptions.Item>
                                        <InputNumber min={0} defaultValue={tm.filmFinish}
                                            onChange={(num) => updateFinishNum(tm, num, 'film')} />
                                    </Descriptions.Item>

                                    <Descriptions.Item>
                                        翻拍健康视频: {tm.bomTarget}条
                                    </Descriptions.Item>
                                    <Descriptions.Item>
                                        <InputNumber min={0} defaultValue={tm.bomFinish}
                                            onChange={(num) => updateFinishNum(tm, num, 'bom')} />
                                    </Descriptions.Item>

                                </Descriptions>
                            ))
                        )
                    }
                </PageHeader>
            </Col>

            <Col xs={24} sm={24} md={12}>
                <PageHeader
                    ghost={false}
                    // onBack={() => window.history.back()}
                    title='接龙文本'
                    // subTitle="This is a subtitle"
                    extra={[
                        <Button key="1" type="primary" onClick={copy}>复制</Button>,
                    ]}
                >
                    <Descriptions size="small">
                        <Descriptions.Item className='strong'>
                            <pre>{txt}</pre>
                        </Descriptions.Item>
                    </Descriptions>

                </PageHeader>
            </Col>
        </Row>
    </div>;
}

export default Home;
