import {createRoot} from 'react-dom/client';

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import './index.less'

import Home from "./pages/home";

const rootElement = document.getElementById("root");
createRoot(rootElement)
    .render(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
            </Routes>
        </BrowserRouter>,
    );
